.form-field {
    color: black;
}

.success-message {
    color: black;
}

.error-handling {
    color: red;
}

.form-field {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}


.input-table{
    width: 100%;
    display: inline;
}

.form-field-button{
    background-color: #04AA6D;
    border: none;
    color: white;
    padding: 16px 32px;
    text-decoration: none;
    margin: 4px 2px;
    cursor: pointer;
}

.main-table-table{
    border-radius: 5px;
    background-color: #f2f2f2;
    padding: 20px;
}