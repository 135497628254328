.builtby {
    color: white;
    text-align: center
}

.table-footer {
    display: block;
}

.footer-row-one {
    display: block;
}

.footer-row-two {
    display: block;
}

/* Style all font awesome icons */
.fa {
    padding: 20px;
    font-size: 30px;
    width: 30px;
    text-align: center;
    text-decoration: none;
    border-radius: 50%;
  }
  
  /* Add a hover effect if you want */
  .fa:hover {
    opacity: 0.7;
  }
  
  /* Set a specific color for each brand */
  
  /* Facebook */
  .fa-facebook {
    background: #3B5998;
    color: white;
  }
  
  /* Twitter */
  .fa-twitter {
    background: #55ACEE;
    color: white;
  }

  .fa-soundcloud {
    background: #ff5500;
    color: white;
  }

  .fa-youtube {
    background: #bb0000;
    color: white;
  }

  .fa-instagram {
    background: #125688;
    color: white;
  }

  .fa-reddit {
    background: #ff5700;
    color: white;
  }

  fa-tiktok {
    background: #ff5700;
    color: white;
  }
  fa-discord {
    background: #ff5700;
    color: white;
  }