.bio {
    color: white;
}

.biodiv {
    box-shadow: 0 4px 8px 0 rgb(255, 255, 255);
    padding: 2px 16px;
    transition: 0.3s;
    /* width: 40%; */
}

@media only screen and (max-width: 600px) {
    .row-with-picture {
        display: grid;
    }
    .picture-of-me {
        width: 100%;
    }
}